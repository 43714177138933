import React from "react";
import ContextToggle from "../ContextToggle";
import ChatLoader from "../ChatLoader";
import BrandwizerLogoIcon from "../../img/logo-icon.svg";

interface ChatMessageProps {
  message: {
    id: number;
    text: string;
    type: string;
    contextVisible?: boolean;
    context: string;
  };
  toggleContext: () => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  toggleContext,
}) => {
  const isUserMessage = message.type === "user";

  return (
    <div
      className={`flex items-start p-4 my-2 ${
        isUserMessage ? "bg-secondary-100 text-right" : "bg-white text-left"
      } ${isUserMessage ? "ml-auto" : "mr-auto"} rounded-3xl md:max-w-[80%]`}
    >
      {!isUserMessage && (
        <img
          src={BrandwizerLogoIcon}
          alt="User Profile"
          className="mt-1 w-4 h-4 rounded-full mr-2"
        />
      )}
      <div>
        {message.text === "Loading..." && (
          <div className="">
            <ChatLoader />
          </div>
        )}
        {message.type === "system" && message.text !== "Loading..." && (
          <>
            <ContextToggle
              contextVisible={message.contextVisible ?? false}
              toggleContext={toggleContext}
              content={message.context}
            />
            {message.contextVisible && <p></p>}
          </>
        )}
        {message.text !== "Loading..." &&
          (message.type !== "system" ? (
            <p dangerouslySetInnerHTML={{ __html: message.text }}></p>
          ) : (
            <p
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: message.text }}
            ></p>
          ))}
      </div>
    </div>
  );
};

export default ChatMessage;
