import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import LogoBlack from "../../img/logo-black.svg";

const Navbar: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [activeTab, setActiveTab] = useState("Prompts");

  if (!isAuthenticated || !user) {
    return null;
  }

  return (
    <header className="sticky top-0 bg-white z-50">
      <nav
        className="mx-auto bg-white flex items-center justify-between p-6 lg:px-8 border-b-2 border-secondary-200"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <img src={LogoBlack} alt="Logo" />
        </div>
        <div className="flex space-x-4">
          {/* 
          // @ts-ignore */}
          <Link to="/prompts">
            <button
              className={`px-4 py-2 rounded-full ${
                activeTab === "Prompts"
                  ? "bg-secondary-50 text-secondary-700"
                  : "bg-white text-black"
              }`}
              onClick={() => setActiveTab("Prompts")}
            >
              Ask your brand
            </button>
          </Link>
          {/* 
          // @ts-ignore */}
          <Link to="/insights">
            <button
              className={`px-4 hidden md:inline-block py-2 rounded-full ${
                activeTab === "Insights"
                  ? "bg-secondary-50 text-black"
                  : "bg-white text-black"
              }`}
              onClick={() => setActiveTab("Insights")}
            >
              Insights
            </button>
          </Link>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end text-black font-bold">
          <Popover className="relative">
            <Popover.Button className="focus:outline-none">
              <p>{user.name}</p>
            </Popover.Button>
            <Popover.Panel className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
              <div className="px-4 py-2 border-b border-gray-300">
                <p className="text-gray-700">{user.email}</p>
              </div>

              <button
                className="w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100"
                onClick={() => logout({})}
              >
                Logout
              </button>
            </Popover.Panel>
          </Popover>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
