import React from "react";
import ArrowUpIcon from "../../icons/ArrowUp.svg";

interface ChatCardProps {
  text: string;
  onClick: () => void;
}

const ChatCard: React.FC<ChatCardProps> = ({ text, onClick }) => {
  return (
    <div
      className="p-4 bg-secondary-100 rounded-lg hover:bg-purple-100 cursor-pointer border-2 border-secondary-200 flex flex-col justify-between"
      onClick={onClick}
      style={{ minHeight: "150px" }} // Adjust as necessary for consistent card height
    >
      <p>{text}</p>
      <div className="flex justify-end items-end">
        <button className="bg-white p-2 rounded-full mt-4">
          <img src={ArrowUpIcon} alt="Send message" />
        </button>
      </div>
    </div>
  );
};

export default ChatCard;
