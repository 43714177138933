// src/layouts/Layout.tsx
import React, { ReactNode } from "react";
import Navbar from "../NavBar";
import Sidebar from "../../components/Sidebar";
import { useDataContext } from "../../context/DataContext";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  // Add selectedItem later
  const { setSelectedItem, data } = useDataContext();
  console.log(data, "data");
  const handleSelect = (item: string) => {
    setSelectedItem(item);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar
          onSelect={handleSelect}
          items={data?.items.map((item: any) => item.label) || []}
        />
        <div className="flex-1 md:mx-4 mt-8">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
