import React from "react";

interface ChartCardProps {
  title: string;
  children: React.ReactNode;
  explanationLink: string;
}

const ChartCard: React.FC<ChartCardProps> = ({
  title,
  children,
  explanationLink,
}) => {
  return (
    <div className="bg-white rounded-lg shadow mb-16">
      <div className="text-secondary-900 font-bold p-4 text-lg">{title}</div>
      <div className="p-4">
        <div className="relative min-h-64">{children}</div>
      </div>
      <div className="mt-4 bg-secondary-50 p-4 rounded-b-lg text-right">
        <a href={explanationLink} className="underline text-gray-600">
          View explanation
        </a>
      </div>
    </div>
  );
};

export default ChartCard;
