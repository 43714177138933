// src/context/DataContext.tsx
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import mockData from "../data/data.json";
interface DataContextProps {
  selectedItem: string | null;
  setSelectedItem: (item: string) => void;
  data: any;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    return setData(mockData);
    // fetch('./data/data.json')
    //   .then(response => response.json())
    //   .then(data => setData(data));
  }, []);

  return (
    <DataContext.Provider value={{ selectedItem, setSelectedItem, data }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useDataContext = (): DataContextProps => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export { DataContext };
