import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import ChartCard from "../Card";

const LineChart: React.FC = () => {
  const data = {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "Nike",
        data: [40, 42, 44, 50, 55, 58, 65, 68, 70, 73, 75, 78, 80],
        borderColor: "#1f77b4",
        fill: false,
        tension: 0.4, // Makes the line more curved
        pointRadius: 0, // Removes the points
      },
      {
        label: "Adidas",
        data: [45, 48, 52, 55, 58, 62, 65, 68, 72, 75, 78, 82, 85],
        borderColor: "#ff7f0e",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: "Under Armour",
        data: [30, 32, 35, 38, 40, 45, 50, 52, 55, 60, 62, 65, 70],
        borderColor: "#2ca02c",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: "Lululemon",
        data: [35, 37, 40, 42, 45, 48, 50, 55, 60, 62, 65, 70, 75],
        borderColor: "#d62728",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: "Puma",
        data: [20, 25, 28, 30, 35, 38, 40, 45, 50, 52, 55, 57, 60],
        borderColor: "#9467bd",
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "line" as const,
          font: {
            family: "Space Grotesk", // Add your font here to change the font of your y axis
            size: 12,
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Space Grotesk", // Add your font here to change the font of your y axis
            size: 12,
          },
        },
      },
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          font: {
            family: "Space Grotesk", // Add your font here to change the font of your y axis
            size: 12,
          },
        },
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <ChartCard
      title="Brand awareness of the top 5 competitor brands"
      explanationLink="/explanation-link"
    >
      <Line data={data} options={options} className="font-sans-serif" />
    </ChartCard>
  );
};

export default LineChart;
