import React, { useState } from "react";
import ChatMessage from "../../components/ChatMessage";
import ChatInput from "../../components/ChatInput";
import ChatCardGrid from "../../components/ChatCardGrid";
import { getAccessToken } from "../../utils";
import { useAuth0 } from "@auth0/auth0-react";

type MessageType = {
  id: number;
  text: string;
  type: "user" | "system";
  context: string;
  contextVisible?: boolean;
};

const Prompts: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  console.log("Hallo");

  const handleSendMessage = async (message: string) => {
    // Add an example POST fetch here:

    const userMessage: MessageType = {
      id: messages?.length + 1,
      text: message,
      type: "user",
      context: "",
    };
    const loadingMessage: MessageType = {
      id: messages.length + 2,
      text: "Loading...",
      type: "system",
      context: "",
    };

    setMessages([...messages, userMessage, loadingMessage]);

    try {
      const previousPrompts = messages
        .map((msg) => `${msg.text} (${msg.context})`)
        .join("   PROMPTS BEFORE:");

      console.log(previousPrompts);

      const token = await getAccessToken(getAccessTokenSilently);
      const response = await fetch("https://api.brandwizer.eu/query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          prompt:
            message + "   PREVIOUS PROMPTS AFTER THIS: " + previousPrompts,
        }),
      });

      const data = await response.json();

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === loadingMessage.id
            ? { ...msg, text: data.result, context: data.context }
            : msg,
        ),
      );
    } catch (error) {
      console.error("Error:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === loadingMessage.id
            ? {
                ...msg,
                text: "An error occurred. Please try again.",
                context:
                  "The demo version is not connected to the backend yet. It is not possible to see a response.",
              }
            : msg,
        ),
      );
    }
  };

  const toggleContext = (id: number) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === id ? { ...msg, contextVisible: !msg.contextVisible } : msg,
      ),
    );
  };

  const handleCardClick = (text: string) => {
    handleSendMessage(text);
  };

  return (
    <div className="flex flex-col h-full mx-6 md:w-[80%] md:mx-auto relative">
      {messages.length === 0 ? (
        <ChatCardGrid onCardClick={handleCardClick} />
      ) : (
        <div className="flex-1 overflow-y-auto mb-20 pb-16">
          {messages.map((msg) => (
            <ChatMessage
              key={msg.id}
              message={msg}
              toggleContext={() => toggleContext(msg.id)}
            />
          ))}
        </div>
      )}
      <div className="fixed bottom-0 left-0 w-full bg-white shadow-lg">
        <div className="mx-6 md:w-[80%] md:mx-auto p-4">
          <ChatInput onSendMessage={handleSendMessage} />
        </div>
      </div>
    </div>
  );
};

export default Prompts;
