import React, { useState } from "react";
import ArrowUpIcon from "../../icons/ArrowUp.svg";

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  className?: string;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  className = "",
}) => {
  const [input, setInput] = useState<any>("");

  const handleSend = () => {
    if (input.trim() !== "") {
      onSendMessage(input);
      setInput("");
    }
  };

  return (
    <div
      className={`md:p-2 bg-secondary-100 rounded-full flex items-center ${className}`}
    >
      <input
        type="text"
        className="flex-1 ml-2 py-2 border-none focus:outline-none text-secondary-800 bg-secondary-100 px-4"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && handleSend()}
        placeholder="Enter your prompt"
      />
      <button
        className="ml-2 p-3 bg-secondary-200 rounded-full focus:outline-none"
        onClick={handleSend}
      >
        <img
          src={ArrowUpIcon}
          alt="Send message"
          className="transition-transform transform hover:rotate-90 duration-200"
        />
      </button>
    </div>
  );
};

export default ChatInput;
