import React from "react";
import ChevronUp from "../../icons/ChevronUp.svg";

interface ContextToggleProps {
  contextVisible: boolean;
  toggleContext: () => void;
  content: string;
}

const ContextToggle: React.FC<ContextToggleProps> = ({
  contextVisible,
  toggleContext,
  content,
}) => {
  return (
    <>
      <button
        className="text-secondary-500 flex items-center"
        onClick={toggleContext}
      >
        {contextVisible ? (
          <>
            Hide context of this answer
            <img
              src={ChevronUp}
              className="ml-2 inline-block transform transition-transform duration-300 rotate-180"
              style={{
                transform: contextVisible ? "rotate(180deg)" : "rotate(0deg)",
              }}
              alt="Collapse context"
            />
          </>
        ) : (
          <>
            Show context of this answer
            <img
              src={ChevronUp}
              className="ml-2 inline-block transform transition-transform duration-300"
              style={{
                transform: contextVisible ? "rotate(180deg)" : "rotate(0deg)",
              }}
              alt="Expand context"
            />
          </>
        )}
      </button>
      {contextVisible && (
        <div className="p-4 my-2 bg-secondary-100 text-left ml-auto rounded-lg text-secondary-900">
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>
      )}
    </>
  );
};

export default ContextToggle;
