// src/pages/Insights.tsx
import React from "react";
import BarChart from "../../components/charts/BarChart";
import LineChart from "../../components/charts/LineChart";
import { useDataContext } from "../../context/DataContext";

const Insights: React.FC = () => {
  // Set setSelectedItem later
  const { selectedItem, data } = useDataContext();

  if (!selectedItem || !data) {
    return <p>Please select an item from the sidebar.</p>;
  }

  const selectedData = data.items.find(
    (item: any) => item.label === selectedItem,
  );
  if (!selectedData) {
    return <p>No data available for the selected item.</p>;
  }

  return (
    <div className="space-y-8">
      {/* <h1>{selectedData.data.title}</h1> */}
      {selectedData.data.charts.map((chart: any, index: number) => {
        if (chart.type === "BarChart") {
          return (
            <BarChart
              key={index}
              title={chart.type}
              data={chart.data}
              explanationLink="#"
            />
          );
        } else if (chart.type === "LineChart") {
          return (
            <LineChart
              key={index}
              // data={chart.data}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default Insights;
