import React from "react";
import ChartCard from "../Card";

interface BarChartProps {
  title: string;
  data: Array<{ label: string; value: number; color: string; year: string }>;
  explanationLink: string;
}

const BarChart: React.FC<BarChartProps> = ({
  title,
  data,
  explanationLink,
}) => {
  const barWidth = 100 / data.length; // Calculate the width based on the number of bars

  return (
    <ChartCard title={title} explanationLink={explanationLink}>
      <div className="flex flex-col justify-between ">
        <div className="flex justify-around items-end h-full bg-secondary-50 rounded-t-lg">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center mx-2"
              style={{ width: `${barWidth}%` }}
            >
              <div className="text-sm mb-1">{item.label}</div>
              <div
                className="rounded-t-lg"
                style={{
                  backgroundColor: item.color,
                  height: `${item.value * 2}px`,
                  width: "100%",
                }}
              ></div>
              {/* <div className="text-sm mt-2">{item.year}</div> */}
            </div>
          ))}
        </div>
        <div className="text-sm mt-2 flex justify-center">{data[0].year}</div>
      </div>
    </ChartCard>
  );
};

export default BarChart;
