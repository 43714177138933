import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import Layout from "./organisms/Layout";
import Prompts from "./pages/prompts";
import Insights from "./pages/insights";
import { DataProvider } from "./context/DataContext";

const App: React.FC = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (!isLoading && (!isAuthenticated || !user)) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, user, loginWithRedirect]);

  if (!isLoading) {
    return (
      <DataProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Navigate to="/prompts" />} />
              <Route path="/insights" element={<Insights />} />
              <Route path="/prompts" element={<Prompts />} />
            </Routes>
          </Layout>
        </Router>
      </DataProvider>
    );
  }

  return (
    <div>
      <h1>Loading..</h1>
    </div>
  );
};

export default App;
