import React, { useState, useEffect } from "react";
import Card from "../ChatCard";
import { useAuth0 } from "@auth0/auth0-react";

const cardsData: any = [
  {
    text: "How do consumers perceive their own knowledge level and does this differ across genders?",
  },
  {
    text: "What is the current purchase intent for VR headsets in the next 12 months?",
  },
  {
    text: "Which VR brands have the highest awareness among respondents?",
  },
  {
    text: "How do brand perceptions compare across key attributes like 'ease of use', 'comfort', and 'value for money' for the major VR brands?",
  },
  {
    text: "What are the primary barriers preventing interested consumers from purchasing a VR headset?",
  },
  {
    text: "What are the top 3 use cases that current VR headset owners engage in most frequently?",
  },
];

interface ChatCardGridProps {
  onCardClick: (text: string) => void;
}

const ChatCardGrid: React.FC<ChatCardGridProps> = ({ onCardClick }) => {
  const { user } = useAuth0();
  const [maxCards, setMaxCards] = useState(6);

  useEffect(() => {
    const updateMaxCards = () => {
      if (window.innerWidth < 640) {
        setMaxCards(2);
      } else if (window.innerWidth < 1024) {
        setMaxCards(6);
      } else {
        setMaxCards(cardsData.length);
      }
    };

    updateMaxCards();
    window.addEventListener("resize", updateMaxCards);

    return () => window.removeEventListener("resize", updateMaxCards);
  }, []);

  return (
    <div className="p-4">
      <div className="block pb-8">
        <h1 className="text-3xl">
          <span className="text-purple-500 block font-bold">
            Hi {user?.name},
          </span>
          <span className="block pt-1 text-secondary-700 ">
            How can I help you?
          </span>
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {cardsData.slice(0, maxCards).map((card: any, index: number) => (
          <Card
            key={index}
            text={card.text}
            onClick={() => onCardClick(card.text)}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatCardGrid;
