import React from "react";
import { TypeAnimation } from "react-type-animation";

const ChatLoader: React.FC = () => {
  return (
    <div className="inline">
      {/* <Lottie animationData={ChatLoaderIcon} loop={true} className="inline" /> */}
      <TypeAnimation
        sequence={[
          0, // Start immediately
          "Preparing your request...",
          3000, // Wait for 3 seconds
          "We are using the Antrophic model.",
          3000, // Wait for 3 seconds
          "This can take up to a minute.",
          3000, // Wait for 3 seconds
          "We are processing millions of rows of data.",
          3000, // Wait for 3 seconds
          "Double-checking everything...",
          3000, // Wait for 3 seconds
          "Almost there...",
          2000, // Wait for 2 seconds
          "Thank you for your patience.",
        ]}
        wrapper="div"
        cursor={true}
        repeat={0} // Repeat count, 0 means no repeat
        className="inline-block"
      />
    </div>
  );
};

export default ChatLoader;
